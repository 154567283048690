@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

@keyframes spinner-grow-delay {
  to { transform: scale(1, 1.5); background-color: var(--green); }
}
// scss-docs-end spinner-border-keyframes

.spinner-grow-delay {
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: $spinner-vertical-align;
  background-color: transparent;
  border-radius: 50%;

  &::before,
  &::after,
  & > div {
    position: absolute;
    content: "";
    width: 1rem;
    height: 1rem;
    vertical-align: $spinner-vertical-align;
    background-color: currentColor;
    border-radius: 50%;
    animation: 0.9s ease-in-out infinite alternate spinner-grow-delay;
    transition: all 1s;
  }
  
  &::before {
    left: -$spinner-width;
    animation-delay: 0s;
  }

  & > div {
    animation-delay: 0.3s;
  }

  
  &::after {
    right: -$spinner-width;
    animation-delay: 0.6s;
  }
}
