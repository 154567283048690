.print-root * {
  padding: 0 !important;
  margin: 0 !important;
}

*.formated {
  white-space: nowrap;
  font-size: 1rem;
}

.formated.print-title {
  font-size: 1.75rem !important;
  font-weight: bold;
  text-align: center;
}

.observation-space {
  max-width: 90% !important;
  padding-left: 5mm !important;
  word-break: break-all !important;
  white-space: normal !important;
}

.formated.print-row {
  display: flex;
  overflow: hidden;

  div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
      &.complement-space {
        padding-left: 13mm !important;
      }

      &.item-space {
        padding-left: 20mm !important;
      }

      &.transshipment-space {
        padding-left: 5mm !important;
      }
    }
  }
}